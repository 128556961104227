<template>
    <v-card>
        <v-alert type="error" v-if="permission_alert">
            Error!!! Este usuario no tiene permisos en esta ventana
        </v-alert>
        <v-toolbar flat dense class="mb-3" v-if="permission.can_view">
            <v-toolbar-title>{{ titulo }}</v-toolbar-title>
            <widgets-Favorite ruta="/remision" :formulario="titulo" />
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-form ref="form" v-if="permission.can_view">
            <v-card-text>
                <v-row dense>
                    <v-col cols="12" sm="3">
                        <vue-autocomplete-contacto-search label="Proveedor" id="input1"
                            action="contacto/Vact_contacto_data_proveedor"
                            getter="contacto/Vget_contacto_data_proveedor" load="contacto/Vget_contacto_load"
                            vuex="contacto/contacto_create" permission="Proveedores" v-model="contacto"
                            @keyup.native.enter="next(1, contacto)" />
                    </v-col>
                    <v-col cols="12" sm="3">
                        <c-text-date outlined dense label="Inicio" v-model="inicio"></c-text-date>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <c-text-date outlined dense label="Fin" v-model="fin"></c-text-date>
                    </v-col>
                    <v-col cols="12" sm="1">
                        <v-btn text fab small color="primary" outlined @click="obtener()">
                            <v-icon>cloud_download</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <div v-for="(i, index) in Vget_notaremision_reporte" :key="index">
                    <v-sheet outlined :color="index % 2 == 0 ? `deep-purple` : `green`" rounded class="mt-4">
                        <v-card outlined>
                            <v-row dense class="mx-2 my-2">
                                <v-col cols="12" sm="4">
                                    Proveedor: {{ i.proveedor }}
                                </v-col>
                                <v-col cols="12" sm="4">
                                    RUC: {{ i.ruc }}
                                </v-col>
                                <v-col cols="12" sm="4">
                                    Fecha: {{ i.fecha }}
                                </v-col>
                                <v-col cols="12">
                                    <v-alert text outlined :color="i.pendientes ? `deep-orange` : `green`"
                                        :icon="i.pendientes ? `info` : `check`">
                                        {{ i.pendientes ? `No se ha recepcionado todos los productos` : `Completado` }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-data-table :headers="headers" :items="i.detalle">
                                <template v-slot:[`item.cantidad_remitida`]="props">
                                    {{ currency(props.item.cantidad_remitida) }}
                                </template>
                                <template v-slot:[`item.cantidad`]="props">
                                    {{ currency(props.item.cantidad) }}
                                </template>
                                <template v-slot:[`item.costo`]="props">
                                    {{ currency(props.item.costo) }}
                                </template>
                                <template v-slot:[`item.total`]="props">
                                    {{ currency(props.item.costo * props.item.cantidad) }}
                                </template>
                            </v-data-table>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-chip class="mb-2" color="green" outlined>
                                    Total: {{ currency(total(i.detalle)) }}
                                </v-chip>
                            </v-card-actions>
                        </v-card>
                    </v-sheet>
                </div>
            </v-card-text>
        </v-form>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { NOTA_REMISION } from "@/constants/forms";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { currency } from "@/services/util/number.service";
import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR
} from "@/constants/permissions"
export default {
    data() {
        return {
            titulo: "Reporte Nota Remision",
            inicio: null,
            contacto: {
                idcontacto: null
            },
            fin: null,
            permission: {
                can_view: false
            },
            headers: [
                {
                    text: "Producto",
                    align: "left",
                    value: "name",
                },
                {
                    text: "Cantidad",
                    align: "right",
                    value: "cantidad",
                },
                {
                    text: "Cantidad Remitida",
                    align: "right",
                    value: "cantidad_remitida",
                },
                {
                    text: "Precio",
                    align: "right",
                    value: "costo",
                },
                {
                    text: "Subtotal",
                    align: "right",
                    value: "total",
                }
            ]
        }
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout")
        const permission = getPermission(NOTA_REMISION).permiso
        this.permission = {
            can_add: permission[PUEDE_AGREGAR],
            can_view: permission[PUEDE_LISTAR],
            can_update: permission[PUEDE_EDITAR],
            can_delete: permission[PUEDE_ELIMINAR],
        }
    },
    computed: {
        ...mapGetters("notaremision", ["Vget_notaremision_reporte"]),
        permission_alert() {
            return mensaje.permission(this.permission)
        }
    },
    methods: {
        ...mapActions("notaremision", ["Vact_notaremision_reporte"]),
        obtener() {
            this.Vact_notaremision_reporte({ contacto: this.contacto.idcontacto, inicio: this.inicio, fin: this.fin })
        },
        currency(value) {
            return currency(value);
        },
        total(value) {
            let total = 0
            value.forEach(element => {
                total += element.costo * element.cantidad
            });
            return total
        }
    }
}
</script>