<template>
    <v-dialog :value="value" persistent max-width="900">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ titulo }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form">
                    <v-row dense>
                        <v-col cols="6" md="3" sm="3">
                            <c-text-date label="Fecha" id="input0" v-model="data.fechcomprapedido"
                                @keyup.native.enter="next(0, data.fechcomprapedido)"></c-text-date>
                        </v-col>
                        <v-col cols="6" md="3" sm="3">
                            <c-text-date :readonly="readonly" label="Fecha Vencimiento" id="input1"
                                v-model="data.fechavencimiento"
                                @keyup.native.enter="next(1, data.fechavencimiento)"></c-text-date>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" md="3" sm="3">
                            <vue-autocomplete-generic :readonly="readonly" id="input2"
                                action="estadopedido/Vact_estadopedido_data"
                                getter="estadopedido/Vget_estadopedido_data" load="estadopedido/Vget_estadopedido_load"
                                pk="idestadopedido" label="Seleccione estado" title="descestadopedido"
                                permission="EstadoPedido" v-model="data.idestadopedido.idestadopedido"
                                @keyup.native.enter="next(2, data.idestadopedido.idestadopedido)" class="mt-4" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="6" sm="6">
                            <vue-autocomplete-contacto-search :readonly="readonly" label="Proveedor" id="input3"
                                action="contacto/Vact_contacto_data_proveedor"
                                getter="contacto/Vget_contacto_data_proveedor" load="contacto/Vget_contacto_load"
                                vuex="contacto/contacto_create" permission="Proveedores" v-model="data.idcontacto"
                                @keyup.native.enter="next(3, data.idcontacto)" />
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                            <vue-autocomplete-generic :readonly="readonly" id="input4" action="moneda/Vact_moneda_data"
                                getter="moneda/Vget_moneda_data" load="moneda/Vget_moneda_load" pk="idmoneda"
                                label="Seleccione moneda" title="descmoneda" permission="Moneda"
                                v-model="data.idmoneda.idmoneda"
                                @keyup.native.enter="next(4, data.idmoneda.idmoneda)" />
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                            <vue-text-field :readonly="readonly" id="input5" :rules="false" label="Numero"
                                v-model="data.numpedido" @keyup.native.enter="next(5, data.numpedido)"></vue-text-field>
                        </v-col>
                    </v-row>
                </v-form>
                <v-form ref="formDet">
                    <v-row dense v-if="!readonly">
                        <v-col cols="12" md="6" sm="6">
                            <v-autocomplete dense v-model="detalle.iditem" id="input6" v-bind:items="item"
                                :filter="customFilter" :search-input.sync="searchitem" label="Item" item-text="descitem"
                                return-object :loading="Vget_item_load" item-value="iditem" outlined
                                @change="obtenerPrecio()" autocomplete="off"
                                @keyup.enter="next(6, detalle.iditem.iditem)"
                                :no-data-text="`No existe ninguna concidencia`">
                                <template v-if="item_permission" v-slot:no-data>
                                    <v-list-item @click="formularios.item = true">
                                        <v-list-item-content>
                                            <v-list-item-title>No existe concidencia. Click para
                                                agregar</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                            <div v-if="formularios.item">
                                <Item-Item-create :sync-input="searchitem" @sync-input="
                                    (detalle.iditem = $event),
                                    next(6, detalle.iditem.iditem)
                                    " @item="searchItemId = $event" v-model="formularios.item" :crudMode="'COMPRA'" />
                            </div>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <vue-text-currency label="Precio" id="input7" v-model="detalle.precio" :rules="true"
                                :rules-option="validarPrecio"
                                @keyup.native.enter="next(7, detalle.precio)"></vue-text-currency>
                        </v-col>
                        <v-col cols="8" sm="2" md="2">
                            <vue-text-currency label="Cantidad" id="input8" v-model="detalle.cantidad" :rules="true"
                                :rules-option="validarCantidad"
                                @keyup.native.enter="next(8, detalle.cantidad)"></vue-text-currency>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" id="input9" small fab outlined text @click="addTable()">
                            <v-icon>add</v-icon>
                        </v-btn>
                    </v-row>
                    <v-data-table :headers="readonly ? headers2 : headers" :items="data.pedido" hide-default-footer :items-per-page="1000000"
                        item-key="iditem.iditem" @click:row="clickRow" :expanded="expanded" show-expand>
                        <template v-slot:[`item.remitidos`]="props">
                            {{ currency(props.item.remitidos) }}
                        </template>
                        <template v-slot:[`item.pendientes`]="props">
                            {{ currency(props.item.cantidad - props.item.remitidos) }}
                        </template>
                        <template v-slot:[`item.precio`]="props">
                            {{ currency(props.item.precio) }}
                        </template>
                        <template v-slot:[`item.cantidad`]="props">
                            {{ currency(props.item.cantidad) }}
                        </template>
                        <template v-slot:[`item.total`]="props">
                            {{ currency(props.item.precio * props.item.cantidad) }}
                        </template>
                        <template v-slot:[`item.accion`]="props">
                            <v-icon small class="mr-1" @click="editarDetalle(props.item)">edit</v-icon>
                            <v-icon small class="mr-1" @click="eliminarDetalle(props.item)">delete</v-icon>
                        </template>
                        <template v-slot:[`expanded-item`]="{ headers, item }">
                            <td :colspan="headers.length">
                                {{ quimica(item.iditem.itemquimica) }}
                            </td>
                        </template>
                    </v-data-table>
                    <v-row dense class="mt-2">
                        <v-col cols="12">
                            <vue-text-field :rules="false" :readonly="readonly" label="Observacion"
                                v-model="data.observacion"></vue-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn color="red" text @click="cancelar()">
                    <v-icon>clear</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="!readonly" color="primary" text @click="guardar()">
                    <v-icon>done</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { current_date } from "@/services/util/date.service"
import { focus } from "@/services/focus.service";
import { mensaje } from "@/services/notify.service";
import { ITEM } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { currency } from "@/services/util/number.service";
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        datos: {
            type: Object,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            expanded: [],
            item: [],
            searchItemId: null,
            searchitem: "",
            formularios: {
                item: false
            },
            validarPrecio: [
                () =>
                    !(Number(this.detalle.precio) <= 0) ||
                    "Este campo no puede ser menor o igual a 0",
                () => !!this.detalle.precio || "Este campo es requerido"
            ],
            validarCantidad: [
                () =>
                    !(Number(this.detalle.cantidad) < 1) ||
                    "Este campo no puede ser menor a 1",
                () => !!this.detalle.cantidad || "Este campo es requerido"
            ],
            data: {
                idcomprapedido: 0,
                fechcomprapedido: current_date(),
                fechavencimiento: current_date(),
                idcontacto: {
                    idcontacto: ""
                },
                idestadopedido: {
                    idestadopedido: 1
                },
                idmoneda: {
                    idmoneda: ""
                },
                numpedido: "",
                observacion: "",
                pedido: [],
            },
            default: {
                idcomprapedido: 0,
                fechcomprapedido: current_date(),
                fechavencimiento: current_date(),
                idcontacto: {
                    idcontacto: ""
                },
                idestadopedido: {
                    idestadopedido: 1
                },
                idmoneda: {
                    idmoneda: ""
                },
                numpedido: "",
                observacion: "",
                pedido: [],
            },
            detalle: {
                iditem: {
                    iditem: ""
                },
                cantdetcomprapedido: 0,
                precio: 0,
                subtotal: 0,
            },
            detalle_default: {
                iditem: {
                    iditem: ""
                },
                cantdetcomprapedido: 0,
                precio: 0,
                subtotal: 0,
            },
            headers: [
                {
                    text: "Producto",
                    align: "left",
                    value: "iditem.descitem",
                },
                {
                    text: "Cantidad",
                    align: "right",
                    sortable: false,
                    value: "cantidad",
                },
                {
                    text: "Precio",
                    align: "right",
                    sortable: false,
                    value: "precio",
                },
                {
                    text: "Total",
                    align: "right",
                    sortable: false,
                    value: "total",
                },
                {
                    text: "",
                    align: "right",
                    sortable: false,
                    value: "accion",
                },
                {
                    text: "",
                    value: "data-table-expand",
                    align: "right",
                },
            ],
            headers2: [
                {
                    text: "Producto",
                    align: "left",
                    value: "iditem.descitem",
                },
                {
                    text: "Cantidad",
                    align: "right",
                    sortable: false,
                    value: "cantidad",
                },
                {
                    text: "Precio",
                    align: "right",
                    sortable: false,
                    value: "precio",
                },
                {
                    text: "Total",
                    align: "right",
                    sortable: false,
                    value: "total",
                },
                {
                    text: "Cantidad Remitida",
                    align: "right",
                    sortable: false,
                    value: "remitidos",
                },
                {
                    text: "Cantidad Pendiente",
                    align: "right",
                    sortable: false,
                    value: "pendientes",
                },
                {
                    text: "",
                    align: "right",
                    sortable: false,
                    value: "accion",
                },
                {
                    text: "",
                    value: "data-table-expand",
                    align: "right",
                },
            ],
            abriritem: false,
            operacion: true,
        }
    },
    watch: {
        Vget_item_data(val) {
            this.item = [];
            this.item = JSON.parse(JSON.stringify(val));
            if (this.searchItemId != null) return this.search()
        },
        searchitem(val) {
            this.obtain_item(val)
        },
        searchItemId() {
            this.search();
        },
    },
    mounted() {
        if (this.datos != null) {
            this.data = JSON.parse(JSON.stringify(this.datos))
            this.operacion = false
        }
        setTimeout(() => document.getElementById("input1").focus(), 200);
    },
    computed: {
        ...mapGetters("pedidocompra", ["Vget_pedidocompra_save_load"]),
        ...mapGetters("item", ["Vget_item_data", "Vget_item_load"]),
        titulo() {
            if (this.readonly) {
                return "Ver Pedido"
            }
            if (this.operacion) {
                return "Agregar Pedido";
            }
            return "Modificar Pedido";
        },
        item_permission() {
            return getPermission(ITEM).permiso[PUEDE_AGREGAR];
        },
    },
    methods: {
        ...mapActions("pedidocompra", ["Vact_pedidocompra_save", "Vact_pedidocompra_update", "Vact_pedidocompra_data"]),
        ...mapActions("item", ["Vact_item_data", "item_update"]),
        cancelar() {
            this.Vact_pedidocompra_data({ limit: 100, desc: null })
            this.$emit("input", false);
            this.$emit("datos", null);
        },
        currency(value) {
            return currency(value);
        },
        clickRow(item, event) {
            if (event.isExpanded) {
                const index = this.expanded.findIndex((i) => i === item);
                this.expanded.splice(index, 1);
            } else {
                this.expanded.push(item);
            }
        },
        quimica(data) {
            var contenido = ""
            data.forEach((x) => {
                if (x.porcentaje != null) {
                    contenido = contenido + `${x.descripcion} ${x.porcentaje}%; `
                } else {
                    contenido = contenido + `${x.descripcion}`
                }
            })
            return contenido
        },
        next(id, data) {
            if (data === "" || data <= 0) return null;
            focus.nextid(id, data);
        },
        obtain_item(val) {
            if (val != null && val.length == 3) {
                this.Vact_item_data({
                    desc: this.searchitem
                })
            } else if (val != null && val.length < 3) {
                this.item = []
            } else if (val == null) {
                this.detalle = JSON.parse(JSON.stringify(this.detalle_default))
            }
        },
        customFilter(item, queryText) {
            const textOne = item.iditem.toString();
            const textTwo = item.descitem.toLowerCase();
            const searchText = queryText.toLowerCase();
            return (
                textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            )
        },
        search() {
            this.item.forEach((x) => {
                if (x.iditem === this.searchItemId) {
                    this.detalle.precio = x.precitem
                    return
                }
            })
        },
        obtenerPrecio() {
            if (this.detalle.iditem.idunidadmedida == null || this.detalle.iditem.idunidadmedida == undefined) {
                this.item_update(this.detalle.iditem);
                this.searchitem = this.detalle.iditem.descitem;
                this.formularios.item = true;
                this.$notify(mensaje.error("Verifique la unidad de medida del item"));
            }
            if (this.detalle.iditem.precitem != undefined)
                this.detalle.precio = this.detalle.iditem.precitem;
        },
        isExist() {
            let exist = false
            this.data.pedido.forEach(item => {
                if (item.iditem.iditem === this.detalle.iditem.iditem)
                    exist = true
            })
            return exist
        },
        isValidDetail() {
            if (
                !this.$refs.formDet.validate() ||
                this.isExist()
            )
                return false
            return true
        },
        addTable() {
            if (!this.isValidDetail())
                return this.$notify(mensaje.error("Verifique los datos ingresados"))
            this.detalle.subtotal = (
                Number(this.detalle.precio) * Number(this.detalle.cantidad)
            ).toFixed(2)
            this.data.pedido.push(this.detalle)
            this.detalle = JSON.parse(JSON.stringify(this.detalle_default))
            this.$refs.formDet.resetValidation()
            setTimeout(() => document.getElementById("input6").focus(), 200)
        },
        async guardar() {
            if (!this.$refs.form.validate() || this.data.pedido.length === 0)
                return this.$notify(mensaje.completar())
            let a;
            if (this.operacion) {
                a = await this.Vact_pedidocompra_save(this.data)
            } else {
                let id = this.data.idcomprapedido
                a = await this.Vact_pedidocompra_update({ id, data: this.data })
            }
            if (!a.estado) {
                return a.info.map(x => {
                    this.$notify(mensaje.error(x))
                })
            }
            this.$notify(a.info)
            this.operacion = true
            this.$refs.form.resetValidation()
            this.limpiarCampos()
            setTimeout(() => document.getElementById("input1").focus(), 200)
        },
        limpiarCampos() {
            this.data = JSON.parse(JSON.stringify(this.default))
            this.detalle = JSON.parse(JSON.stringify(this.detalle_default))
        },
        editarDetalle(item) {
            this.Vact_item_data({
                desc: item.iditem.descitem
            })
            const index = this.data.pedido.indexOf(item)
            this.data.pedido.splice(index, 1)
            this.detalle = JSON.parse(JSON.stringify(item))
        },
        eliminarDetalle(item) {
            const index = this.data.pedido.indexOf(item)
            this.data.pedido.splice(index, 1)
        }
    }
}
</script>