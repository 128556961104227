<template>
    <div>
        <Configuracion v-if="configurar" v-model="configurar" />
        <v-alert type="error" v-if="!permission.reporte">Error!!! Este usuario no tiene permisos en esta
            ventana</v-alert>
        <v-toolbar flat dense>
            <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
            <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <c-text-date label="Fecha de Liquidacion" ref="fecha" v-model="fecha" outlined dense class="mr-2"
                single-line hide-details></c-text-date>
            <v-toolbar-items>
                <c-btn-table-download @click="descargarPago2()" v-if="permission.reporte"
                    class="mr-2"></c-btn-table-download>
                <c-btn-table-change @click="configurar = true"></c-btn-table-change>
            </v-toolbar-items>
        </v-toolbar>
    </div>
</template>
<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { PAGO_SALARIO } from "@/constants/forms";
import {
    PUEDE_AGREGAR,
} from "@/constants/permissions";
import { mapActions, mapGetters } from "vuex";
import { currency } from "@/services/util/number.service";
import Configuracion from "./Configuracion.vue";
import { trueGETBlob } from "@/services/api/api.service";
export default {
    components: {
        Configuracion
    },
    data() {
        return {
            validar: [(v) => !!v || "Seleccion un campo"],
            permission: {
                reporte: false,
            },
            configurar: false,
            fecha: null,
            data: {
                year: 2025,
                month: 1,
                day: 1,
            },
            consulta: {
                desde: null,
                hasta: null
            },
            headers: []
        }
    },
    computed: {
        ...mapGetters("salario", ["getArchivoPagoConfig", "isArchivoPagoConfigLoading"]),
    },
    watch: {
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout")
        const permiso = getPermission(PAGO_SALARIO).permiso
        this.permission.reporte = permiso[PUEDE_AGREGAR]
    },
    mounted() {
    },
    methods: {
        ...mapActions("salario", ["fetchArchivoPagoConfig"]),
        async descargarPago2() {

            await trueGETBlob("/apigetarchivopago/", this.fecha);
        },
        toCurrency(value) {
            return currency(value);
        },
    }
}
</script>